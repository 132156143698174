import axios from "axios";

const api = process.env.MIX_API_URL;

const state = {
    payments: [],
    typesOfPayments: [],
    isFetching: false
};

const getters = {
    allPayments: state => state.payments,
    typesOfPayments: state => state.typesOfPayments,
    paymentsFetchStatus: state => state.isFetching
};

const actions = {
    async fetchPayments({ commit, state }) {
        try {
            state.isFetching = true;
            const response = await axios.get(`/get/payments`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            });
            commit("SET_PAYMENTS", response.data);
            state.isFetching = false;
        } catch (error) {
            console.log("Couldn't get payments");
            state.isFetching = false;
        }
    },
    async fetchTypeOfPayments({ commit }) {
        try {
            const response = await axios.get(`${api}/typesofpayments`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            });
            commit("SET_TYPES_OF_PAYMENTS", response.data);
        } catch (error) {
            console.log("Couldn't get payments");
        }
    }
};

const mutations = {
    SET_PAYMENTS: (state, payments) => (state.payments = payments),
    SET_TYPES_OF_PAYMENTS: (state, types) => (state.typesOfPayments = types)
};

export default {
    state,
    getters,
    actions,
    mutations
};
