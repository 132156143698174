import axios from "axios";

const state = {
    paymentDetails: [],
    isFetching: false
};

const getters = {
    paymentDetails: state => state.paymentDetails,
    paymentDetailsFetchStatus: state => state.isFetching
};

const actions = {
    async fetchReport({ commit, state }) {
        try {
            state.isFetching = true;
            const response = await axios.get(`/report/paymentdetails`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            });
            commit("SET_PAYMENT_DETAILS", response.data);
            state.isFetching = false;
        } catch (error) {
            console.log("Couldn't get report");
            state.isFetching = false;
        }
    }
};

const mutations = {
    SET_PAYMENT_DETAILS: (state, payments) => (state.paymentDetails = payments)
};

export default {
    state,
    getters,
    actions,
    mutations
};
