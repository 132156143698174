import axios from "axios";

const api = process.env.MIX_API_URL;

const state = {
    user: null,
    isFetching: false,
    isAuthenticated: false
};

const getters = {
    user: state => state.user,
    userFetchStatus: state => state.isFetching,
    userTypeID: state => state.user.user_type.id,
    isAuthenticated: state => state.isAuthenticated,
    isSupervizor: state => state.user.user_type.id === 1,
    isAdmin: state => state.user.user_type.id === 2,
    isProfesor: state => state.user.user_type.id === 3,
    isStudent: state => state.user.user_type.id === 4
};

const actions = {
    async fetchLoggedUser({ commit, state }) {
        try {
            commit("SET_FETCHING", true);
            const response = await axios.get(`/user`);
            commit("SET_USER", response.data[0]);
            commit("SET_FETCHING", false);
            commit("SET_AUTHENTICATED", true);
        } catch (error) {
            console.log("Couldn't get logged user");
        }
    }
};

const mutations = {
    SET_USER: (state, user) => (state.user = user),
    SET_AUTHENTICATED: (state, isAuthenticated) =>
        (state.isAuthenticated = isAuthenticated),
    SET_FETCHING: (state, isFetching) => (state.isFetching = isFetching)
};

export default {
    state,
    getters,
    actions,
    mutations
};
