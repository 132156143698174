import axios from "axios";

const api = process.env.MIX_API_URL;

const state = {
    presetObligations: [],
    presetObligation: {},
    isFetching: false,
    isPosting: false,
    isDeleting: false,
    postPresetStatusCode: null,
    deletePresetStatusCode: null,
    obligation: null
};

const getters = {
    presetObligations: state => state.presetObligations,
    presetObligation: state => state.presetObligation,
    presetObligationsFetchStatus: state => state.isFetching,
    presetObligationsPostStatus: state => state.isPosting,
    specificObligation: state => state.obligation,
    postPresetStatusCode: state => state.postPresetStatusCode,
    deletePresetStatusCode: state => state.deletePresetStatusCode
};

const actions = {
    async fetchPresetObligations({ commit }) {
        try {
            commit("SET_FETCH_STATUS", true);
            const response = await axios.get(`${api}/preset/obligations`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            });
            commit("SET_PRESET_OBLIGATIONS", response.data);
            commit("SET_FETCH_STATUS", false);
        } catch (error) {
            console.log("Couldn't get obligations");
            commit("SET_FETCH_STATUS", false);
        }
    },
    async fetchPresetObligation({ commit }, id) {
        try {
            commit("SET_FETCH_STATUS", true);
            const response = await axios.get(`${api}/preset/obligation/${id}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            });
            commit("SET_PRESET_OBLIGATION", response.data);
            commit("SET_FETCH_STATUS", false);
        } catch (error) {
            console.log("Couldn't get obligations");
            commit("SET_FETCH_STATUS", false);
        }
    },
    async postPresetObligation({ commit }, data) {
        try {
            commit("SET_POST_STATUS", true);
            const response = await axios.post(`/obligation`, {
                data
            });
            commit("SET_POST_STATUS_CODE", response.status);
            commit("SET_POST_STATUS", false);
        } catch (error) {
            console.log("Error while posting obligation");
            commit("SET_POST_STATUS", false);
        }
    },
    async editPresetObligation({ commit }, data) {
        try {
            commit("SET_POST_STATUS", true);
            const response = await axios.post(`/obligation/update`, {
                data
            });
            commit("SET_POST_STATUS_CODE", response.status);
            commit("SET_POST_STATUS", false);
        } catch (error) {
            console.log("Error while posting obligation");
            commit("SET_POST_STATUS", false);
        }
    },
    async deletePresetObligation({ commit }, id) {
        try {
            commit("SET_DELETE_STATUS", true);
            const response = await axios.delete(`/obligation`, {
                params: { id }
            });
            commit("SET_DELETE_STATUS_CODE", response.status);
            commit("SET_DELETE_STATUS", false);
        } catch (error) {
            console.log("Error while posting obligation");
            commit("SET_DELETE_STATUS", false);
        }
    },
    async fetchObligation({ commit }, id) {
        try {
            commit("SET_FETCH_STATUS", true);
            const response = await axios.get(`${api}/user/obligation/${id}`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            });
            commit("SET_OBLIGATION", response.data);
            commit("SET_FETCH_STATUS", false);
        } catch (error) {
            console.log("Couldn't get obligations");
            commit("SET_FETCH_STATUS", false);
        }
    },
    async fetchCycles() {
        try {
            commit("SET_FETCH_STATUS", true);
            const response = await axios.get(`${api}/cycles`, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                }
            });
            commit("SET_CYCLES", response.data);
            commit("SET_FETCH_STATUS", false);
        } catch (error) {
            console.log("Couldn't get obligations");
            commit("SET_FETCH_STATUS", false);
        }
    }
};

const mutations = {
    SET_PRESET_OBLIGATIONS: (state, obligations) =>
        (state.presetObligations = obligations),
    SET_CYCLES: (state, cycles) => (state.cycles = cycles),
    SET_PRESET_OBLIGATION: (state, obligation) =>
        (state.presetObligation = obligation),
    SET_OBLIGATION: (state, obligation) => (state.obligation = obligation),
    SET_FETCH_STATUS: (state, status) => (state.isFetching = status),
    SET_POST_STATUS: (state, status) => (state.isPosting = status),
    SET_POST_STATUS_CODE: (state, status) =>
        (state.postPresetStatusCode = status),
    SET_DELETE_STATUS: (state, status) => (state.isDeleting = status),
    SET_DELETE_STATUS_CODE: (state, status) =>
        (state.deletePresetStatusCode = status)
};

export default {
    state,
    getters,
    actions,
    mutations
};
