<template>
    <nav>
        <v-app-bar class="white" flat app>
            <v-app-bar-nav-icon
                @click.stop="setDrawer"
                v-if="userRole"
            ></v-app-bar-nav-icon>
            <v-app-bar-title class="primary--text bold"
                >Finansijske obaveze studenta</v-app-bar-title
            >
            <v-spacer></v-spacer>

            <div class="d-flex align-center pa-2">
                <div class="d-flex flex-column">
                    <div class="d-none d-sm-flex">{{ user.name }}</div>
                    <div class="d-none d-sm-flex caption">
                        {{ user.user_type.name }}
                    </div>
                </div>
                <v-menu
                    :offset-y="true"
                    origin="center center"
                    transition="slide-x-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="grey" v-bind="attrs" v-on="on">
                            <v-icon>arrow_drop_down</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="logout">
                            <form
                                id="logout-form"
                                action="/logout"
                                method="POST"
                                style="display: none"
                            >
                                <input
                                    type="hidden"
                                    name="_token"
                                    :value="csrf"
                                />
                            </form>
                            <v-list-item-content>
                                <v-list-item-title>Odjava</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-icon right>logout</v-icon>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>
        <v-navigation-drawer
            :width="340"
            v-if="userRole"
            app
            v-model="drawer"
            class="primary elevation-2"
            dark
        >
            <v-card
                class="d-flex justify-center my-5 px-10"
                color="transparent"
                flat
            >
                <v-img
                    max-height="50"
                    contain
                    src="/images/fin_logo.svg"
                ></v-img>
            </v-card>
            <v-list shaped>
                <v-list-item-group color="white">
                    <v-list-item
                        :to="link.route"
                        v-for="(link, i) in links"
                        :key="i"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="link.icon"></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="link.text"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: "Navbar",
    data: () => ({
        drawer: Boolean,
        csrf: document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content"),
        links: [
            { icon: "home", text: "Početna", route: "/" },
            {
                icon: "mdi-account-group",
                text: "Spisak svih studenata",
                route: { name: "DashboardStudents" }
            },
            {
                icon: "mdi-note-multiple",
                text: "Spisak svih uplata",
                route: { name: "Payments" }
            },
            {
                icon: "mdi-note-plus",
                text: "Pridruživanje finansijskih obaveza",
                route: { name: "PresetsJoinStudents" }
            },
            {
                icon: "mdi-note-text",
                text: "Spisak finansijskih obaveza",
                route: { name: "PresetsList" }
            },
            {
                icon: "mdi-file-chart",
                text: "Izvještaj",
                route: { name: "Report" }
            }
        ]
    }),
    computed: {
        ...mapGetters(["user"]),
        userRole: function() {
            return this.user.user_type.id !== 4 && this.user.user_type.id !== 3;
        }
    },
    methods: {
        setDrawer() {
            this.drawer = !this.drawer;
        },
        logout() {
            document.forms["logout-form"].submit();
        }
    },
    created() {
        this.drawer = !this.$vuetify.breakpoint.smAndDown;
    }
};
</script>
