import store from "../store";

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
        meta: {
            title: "Početna"
        }
    },
    {
        path: "/student",
        name: "Student",
        component: () => import("../views/Student.vue"),
        meta: {
            title: "Student"
        }
    },
    {
        path: "/profesor",
        name: "Profesor",
        component: () => import("../views/Profesor.vue"),
        meta: {
            title: "Profesor"
        }
    },
    //Dashboard student
    {
        path: "/dashboard/studenti",
        name: "DashboardStudents",
        component: () => import("../views/dashboard/Students.vue"),
        meta: {
            title: "Spisak svih studenata"
        }
    },
    {
        path: "/dashboard/student/:id",
        name: "StudentProfile",
        component: () => import("../views/dashboard/StudentProfile.vue"),
        meta: {
            title: "Profil studenta"
        }
    },
    {
        path: "/dashboard/student/:id/uredi",
        name: "StudentProfileEdit",
        component: () => import("../views/dashboard/StudentProfileEdit.vue"),
        meta: {
            title: "Uređivanje profila studenta"
        }
    },
    {
        path: "/dashboard/student/:id/dodajobavezu",
        name: "StudentAddObligation",
        component: () => import("../views/dashboard/StudentAddObligation.vue"),
        meta: {
            title: "Dodaj novu obavezu"
        }
    },
    {
        path: "/dashboard/student/:id/urediobavezu/:obligationid",
        name: "StudentEditObligation",
        component: () => import("../views/dashboard/StudentEditObligation.vue"),
        meta: {
            title: "Uređivanje finansijske obaveze"
        },
        props: true
    },
    //Dashboard uplate
    {
        path: "/dashboard/uplate",
        name: "Payments",
        component: () => import("../views/dashboard/Payments.vue"),
        meta: {
            title: "Spisak svih uplata"
        },
        props: true
    },
    //Dashboard preseti
    {
        path: "/dashboard/aktivneobaveze",
        name: "PresetsList",
        component: () => import("../views/dashboard/PresetsList.vue"),
        meta: {
            title: "Spisak finansijskih obaveza"
        }
    },
    {
        path: "/dashboard/dodajobavezu",
        name: "PresetsAdd",
        component: () => import("../views/dashboard/PresetsAdd.vue"),
        meta: {
            title: "Definisanje nove finansijske obaveze"
        }
    },
    {
        path: "/dashboard/pridruziobavezu",
        name: "PresetsJoinStudents",
        component: () => import("../views/dashboard/PresetsJoinStudents.vue"),
        meta: {
            title: "Pridruzivanje finansijske obaveze"
        }
    },
    {
        path: "/dashboard/urediobavezu/:id",
        name: "PresetsEdit",
        component: () => import("../views/dashboard/PresetsEdit.vue"),
        meta: {
            title: "Uređivanje finansijske obaveze"
        }
    },
    //Izvjestaj
    {
        path: "/dashboard/izvjestaj",
        name: "Report",
        component: () => import("../views/dashboard/Report.vue"),
        meta: {
            title: "Izvještaj"
        }
    },
    {
        path: "/dashboard/uplate/obaveza/:id",
        name: "ObligationPayments",
        component: () => import("../views/dashboard/ObligationPayments.vue"),
        meta: {
            title: "Izvještaj"
        }
    }
];

export default routes;
