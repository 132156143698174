<template>
  <v-app style="background: #f8f8fb">
    <!-- <Navbar :user="user" /> -->
    <div v-if="!userFetchStatus">
      <Navbar />
      <v-main class="mx-4 mb-4">
        <transition :name="transitionName" mode="out-in">
          <router-view />
        </transition>
      </v-main>
    </div>
    <div v-else><Loading /></div>
    <!-- <Footer /> -->
  </v-app>
</template>

<script>
import "./styles/app.css";
import Navbar from "./components/Navbar";
import Loading from "./components/Loading";
//import Footer from "./components/Footer";

import { mapGetters } from "vuex";

export default {
  name: "App",
  data: () => ({
    loading: true,
    previousHeight: 0,
    transitionName: "slide",
  }),
  components: {
    Navbar,
    Loading,
    //Footer,
  },
  computed: {
    ...mapGetters(["user", "userFetchStatus", "userTypeID"]),
  },
  watch: {
    $route(to, from) {
      this.transitionName = to.meta.transitionName || from.meta.transitionName;
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      this.transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      document.title = to.meta.title || "FOS";
    },
  },
};
</script>

<style>
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  overflow: hidden;
}

.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}

.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  transform: translate(-2em, 0);
}
</style>